var exports = {};
exports = {
  usStreet: {
    "street": "street",
    "street2": "street2",
    "secondary": "secondary",
    "city": "city",
    "state": "state",
    "zipcode": "zipCode",
    "lastline": "lastLine",
    "addressee": "addressee",
    "urbanization": "urbanization",
    "match": "match",
    "candidates": "maxCandidates"
  },
  usZipcode: {
    "city": "city",
    "state": "state",
    "zipcode": "zipCode"
  },
  internationalStreet: {
    "country": "country",
    "freeform": "freeform",
    "address1": "address1",
    "address2": "address2",
    "address3": "address3",
    "address4": "address4",
    "organization": "organization",
    "locality": "locality",
    "administrative_area": "administrativeArea",
    "postal_code": "postalCode",
    "geocode": "geocode",
    "language": "language"
  },
  usReverseGeo: {
    "latitude": "latitude",
    "longitude": "longitude"
  }
};
export default exports;