export default {
 "name": "smartystreets-javascript-sdk",
 "version": "3.0.0",
 "description": "Quick and easy Smarty address validation.",
 "keywords": [
  "smarty",
  "smartystreets",
  "address",
  "validation",
  "verification",
  "verify",
  "validate",
  "street-address",
  "geocoding",
  "addresses",
  "zipcode",
  "autocomplete",
  "autosuggest",
  "suggestions",
  "international",
  "http",
  "sdk"
 ],
 "main": "index.js",
 "scripts": {
  "test": "mocha 'tests/**/*.js'"
 },
 "author": "Smarty SDK Team <support@smarty.com> (https://www.smarty.com)",
 "license": "Apache-2.0",
 "repository": {
  "type": "git",
  "url": "github:smartystreets/smartystreets-javascript-sdk"
 },
 "devDependencies": {
  "chai": "^4.3.6",
  "mocha": "^9.2.2"
 },
 "dependencies": {
  "axios": "^0.26.1",
  "axios-retry": "3.2.0"
 }
}
;